import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectProduct", "budget", "budgetAllocated", "adrAllocated", "daysAllocated", "unitsAllocated", "isRecurring", "recurringProfile", "deadlines"];

  initialize() {
    this.toggleRecurringProfile();
    this.allocatedChanged();
  }

  connect() {
    $(this.selectProductTarget).select2({
      placeholder: "Select a product"
    });
  }

  isRecurringChanged() {
    this.toggleRecurringProfile();
  }

  budgetChanged() {
    this.budgetAllocatedTarget.value = this.budgetTarget.value;
    this.allocatedChanged();
  }

  allocatedChanged() {
    var dailyWorkingHours = parseFloat(window.current_organization_daily_working_hours);
    var daysAllocated = this.budgetAllocatedTarget.value / this.adrAllocatedTarget.value;
    this.daysAllocatedTarget.value = daysAllocated.toFixed(2).toString().replace('.', ',');
    this.unitsAllocatedTarget.value = (daysAllocated * dailyWorkingHours).toFixed(2).toString().replace('.', ',');
  }
 
  toggleRecurringProfile() {
    var checked = this.isRecurringTarget.checked;
    this.recurringProfileTarget.style.display = checked ? "" : "none";
    this.deadlinesTarget.style.display = checked ? "none" : "";
  }
}