import React, { Component, useState, useEffect } from "react";

/* Import Interfaces */
import { AbsencesInterface } from "./interfaces";

export const Absences = (props: AbsencesInterface) => {

  const [absences, setAbsences] = useState([]);

  useEffect(() => {
    setAbsences(props.absences);
  }, [props]);

  return (
    <div className="flex-grow-1 text-warning text-center">
      {absences.map((h) => h.absence_type).join(", ")}
    </div>
  );
}
