jQuery(document).on("turbolinks:load", () => {

  // Contact form -> select or create company
  jQuery('#referentable_company a.add_fields').data('association-insertion-position', 'before').data('association-insertion-node', 'this');
  jQuery('#referentable_company').bind('cocoon:after-insert', () => {
    jQuery('#referentable_company_from_list').hide();
    jQuery('#referentable_company a.add_fields').hide();
  });
  jQuery('#referentable_company').bind('cocoon:after-remove', () => {
    jQuery('#referentable_company_from_list').show();
    jQuery('#referentable_company a.add_fields').show();
  });

  // Company form -> select or create contact
  jQuery('#referentable_contact a.add_fields').data('association-insertion-position', 'before').data('association-insertion-node', 'this');
  jQuery('#referentable_contact').bind('cocoon:after-insert', () => {
    jQuery('#referentable_contact_from_list').hide();
    jQuery('#referentable_contact a.add_fields').hide();
  });
  jQuery('#referentable_contact').bind('cocoon:after-remove', () => {
    jQuery('#referentable_contact_from_list').show();
    jQuery('#referentable_contact a.add_fields').show();
  });

  // Order form -> select or create project
  jQuery('#order_project a.add_fields').data('association-insertion-position', 'before').data('association-insertion-node', 'this');
  jQuery('#order_project').bind('cocoon:after-insert', () => {
    jQuery('#order_project_from_list').hide();
    jQuery('#order_project a.add_fields').hide();
  });
  jQuery('#order_project').bind('cocoon:after-remove', () => {
    jQuery('#order_project_from_list').show();
    jQuery('#order_project a.add_fields').show();
  });

  // Order form -> select or create project
  jQuery('#deal_company a.add_fields').data('association-insertion-position', 'before').data('association-insertion-node', 'this');
  jQuery('#deal_company').bind('cocoon:after-insert', () => {
    jQuery('#deal_company_from_list').hide();
    jQuery('#deal_company a.add_fields').hide();
  });
  jQuery('#deal_company').bind('cocoon:after-remove', () => {
    jQuery('#deal_company_from_list').show();
    jQuery('#deal_company a.add_fields').show();
  });



});