import { init_company_form, init_company_search } from './companies';



"use strict";
var KTPortletDraggable = function () {

  return {
    //main function to initiate the module
    init: function () {
      jQuery("#kt_sortable_deals_portlets").sortable({
        connectWith: ".kt-portlet__head",
        items: ".kt-portlet",
        opacity: 0.8,
        handle: '.kt-portlet__head',
        coneHelperSize: true,
        placeholder: 'kt-portlet--sortable-placeholder',
        forcePlaceholderSize: true,
        tolerance: "pointer",
        helper: "clone",
        cancel: ".kt-portlet--sortable-empty", // cancel dragging if portlet is in fullscreen mode
        revert: 250, // animation in milliseconds
        update: function (b, c) {
          if (c.item.prev().hasClass("kt-portlet--sortable-empty")) {
            c.item.prev().before(c.item);
          }

          // console.log(c.item.index());

          var deal_id = c.item.data('deal-id');
          var deals_stage = c.item.closest(".col-lg").data('deals-stage');

          $.ajax({
            type: 'PATCH',
            url: `/deals/${deal_id}`,
            dataType: 'json',
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              deal: { stage: deals_stage },
            },
          });
        }
      });
    }
  };
}();



jQuery(document).on('turbolinks:load', (event) => {
  
  KTPortletDraggable.init();

  jQuery('#deal_company').on('cocoon:after-insert', (event) => {
    init_company_form();
    init_company_search();
  });
});