import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";

/* Import Interfaces */
import { TaskListInterface, TaskObject } from "./interfaces";

/* Import Components */
import { Task } from "./task";
import { TaskCreate } from "./taskCreate";

export const TaskList = (props: TaskListInterface) => {

  const { tasks, date, user, dailyHours, addTask, editTask, removeTask } = props;

  const sumUnits = tasks ? tasks.reduce((sum: number, task: TaskObject) => sum + parseFloat(task.units), 0) : 0; 
  const remainingUnits = Number(dailyHours - sumUnits).toFixed(2);

  const taskWidth = (units: number, sumUnits: number) => Math.round(units / Math.max(sumUnits, dailyHours) * 100);

  const renderTasks = () => {
    return tasks && tasks.map((task, i) => (
      <Task
        key={`task-${date}-${task.id}`}
        date={date}
        task={task}
        width={taskWidth(task.units, sumUnits)}
        id={task.id}
        index={i}
        removeTask={removeTask}
        editTask={editTask}
      />
    ));
  }

  const renderTaskCreate = () => {
    return (
      <TaskCreate
        width={taskWidth(remainingUnits, sumUnits)}
        user={user}
        date={date}
        remaingUnits={remainingUnits}
        addTask={addTask}
      />
    );
  };

  return (
    <div
      className="d-flex align-items-stretch p-1 w-100"
      style={{ minHeight: "46px" }}
    >
      {renderTasks()}
      {remainingUnits > 0 && renderTaskCreate()}
    </div>
  );
}