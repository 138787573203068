// Class definition
var KTProfile = function () {
  return {
    init: function () {
      var avatar = new KTAvatar('kt_profile_avatar');
    }
  };
}();

jQuery(document).on('turbolinks:load', function () {
  KTProfile.init();
});