import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";

import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";

import { TaskFormInterface, UserObject, OrderObject } from "./interfaces";

type FormData = {
  orderIds: number[];
  name: string;
  taskType: string;
  userId: number;
  dueDate: number;
  units: number;
  isUnshiftable: boolean;
  isDone:boolean;
  description: string;
};

const TASK_TYPES = [
  { value: 'task', label: 'Tâche' },
  { value: 'email', label: 'Email' },
  { value: 'call', label: 'Appel' },
  { value: 'meeting', label: 'Réunion' },
  { value: 'lunch', label: 'Repas' },
  { value: 'other', label: 'Autre' },
]

export const TaskForm = (props: TaskFormInterface) => {
  const { register, handleSubmit, setValue, control, watch, setError, errors } = useForm<FormData>();

  const { id, task } = props;

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      const usersResult = await axios('/api/v1/users');
      setUsers(usersResult.data.map((u:UserObject) => { return { value: u.id, label: u.name } }));
    }
    async function fetchOrders() {
      const ordersResult = await axios('/api/v1/orders');
      setOrders(ordersResult.data.map((u:OrderObject) => { return { value: u.id, label: u.full_name } }));
    }
    fetchUsers();
    fetchOrders();
  }, [props]);

  const onSubmit = data => {
    var task = data.task;
    var _data = { task: { ...task, user_id: task.user_id.value, order_ids: task.order_ids.map(o => o.value) } }

    axios
      .put(`/api/v1/tasks/${id}`, _data)
      .then(function (response) {
        props.editTask(id, response.data);
        props.cancelEdit();
      })
      .catch(function (error) {
        // Object.entries(error.response.data).forEach(([name, value]) => { setError(name, value.join(", ")) });
        Object.entries(error.response.data).forEach(([name, value]) => { 
          setError(`task[${name}]`, {type: "manual", message: value.join(", ")})
        });
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
      <div className="form-group row string required task_order_ids">
        <label className="col-sm-3 col-form-label string required">
          Prestations <abbr title="required">*</abbr>
        </label>
        <div className={`col-sm-9 ${errors && errors.task && errors.task.order_ids ? 'is-invalid' : ''}`}>
          <Controller
            as={
              <Select
                options={orders}
                isMulti
                placeholder="Sélectionner une ou plusieurs prestations"
                defaultValue={props.task.orders.map(o => { return { value: o.id, label: o.full_name}})}
                styles={{
                  control: (provided, state) => (errors && errors.task && errors.task.order_ids ? {
                    ...provided, borderColor: '#fd397a',
                  } : provided)
                }}
              />
            }
            defaultValue={props.task.orders.map(o => { return { value: o.id, label: o.full_name } })}
            control={control}
            rules={{ required: true}}
            name="task[order_ids]"
            id="task_order_ids"
          />
          {errors && errors.task && errors.task.order_ids && <div className="invalid-feedback">Requis</div>}
        </div>
      </div>

      <div className="form-group row string required task_name">
        <label className="col-sm-3 col-form-label string required">
          Tâche <abbr title="required">*</abbr>
        </label>
        <div className="col-sm-9">
          <input
            className={`form-control ${errors && errors.task && errors.task.name ? 'is-invalid' : ''}`}
            name="task[name]"
            id="task_name"
            defaultValue={props.task.name}
            ref={register({ required: true })}
          />
          {errors && errors.task && errors.task.name && <div className="invalid-feedback">Requis</div>}
        </div>
      </div>

      <div className="form-group row string optional task_task_type">
        <label className="col-sm-3 col-form-label string optional">
          Type
        </label>
        <div className="col-sm-9">
          <Controller
            as={
              <Select
                placeholder="Sélectionner un type de tâche"
                options={TASK_TYPES}
                defaultValue={{ value: props.task.task_type, label: TASK_TYPES.find(t => t.value === props.task.task_type).label}}
              />
            }
            defaultValue={{ value: props.task.task_type, label: TASK_TYPES.find(t => t.value === props.task.task_type).label }}
            control={control}
            name="task[task_type]"
            id="task_task_type"
          />
        </div>
      </div>

      <div className="form-group row string required task_user_id">
        <label className="col-sm-3 col-form-label string required">
          Utilisateur <abbr title="required">*</abbr>
        </label>
          <div className={`col-sm-9 ${errors && errors.task && errors.task.user_id ? 'is-invalid' : ''}`}>
          <Controller
            as={
              <Select
                options={users}
                placeholder="Sélectionner un utilisateur"
                defaultValue={{ value: props.task.user.id, label: props.task.user.name }}
                isClearable={true}
                styles={{
                  control: (provided, state) => (errors && errors.task && errors.task.user_id ? {
                    ...provided, borderColor: '#fd397a',
                  } : provided)
                }}
              />
            }
            control={control}
            defaultValue={{ value: props.task.user.id, label: props.task.user.name }}
            rules={{ required: true }}
            name="task[user_id]"
            id="task_user_id"            
          />
          {errors && errors.task && errors.task.user_id && <div className="invalid-feedback">Requis</div>}

        </div>
      </div>


      <div className="form-group row string required task_due_date">
        <label className="col-sm-3 col-form-label string required">
          Date <abbr title="required">*</abbr>
        </label>
        <div className="col-sm-9">
          <input
            className={`form-control ${errors && errors.task && errors.task.due_date ? 'is-invalid' : ''}`}
            name="task[due_date]"
            id="task_due_date"
            defaultValue={props.task.due_date}
            type="date"
              ref={register({ required: true })}
          />
          {errors && errors.task && errors.task.due_date && <div className="invalid-feedback">{errors.task.due_date.message}</div>}
        </div>
      </div>

      <div className="form-group row string required task_units">
        <label className="col-sm-3 col-form-label string required">
          Unités <abbr title="required">*</abbr>
        </label>

        <div className="col-sm-9">
          <input
            className={`form-control ${errors && errors.task && errors.task.units ? 'is-invalid' : ''}`}
            name="task[units]"
            id="task_units"
            defaultValue={props.task.units}
            type="number"
            step="any"
            ref={register({ required: true })}
            min="0"
          />
          {errors && errors.task && errors.task.units && <div className="invalid-feedback">{errors.task.units.message}</div>}
        </div>
      </div>

      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="px-0"
      >
        + Options
      </Button>

      <Collapse in={open}>
        <div id="example-collapse-text" className="mt-4">
          <div className="form-group row boolean optional task_is_unshiftable">
            <label className="col-sm-3">Non décalable</label>
            <div className="col-sm-9">
              <div className="form-check">
                <input
                  className="form-check-input boolean optional"
                  name="task[is_unshiftable]"
                  id="task_is_unshiftable"
                  type="checkbox"
                  ref={register}
                />
              </div>
            </div>
          </div>

          <div className="form-group row boolean optional task_is_done">
            <label className="col-sm-3">Validée</label>
            <div className="col-sm-9">
              <div className="form-check">
                <input
                  className="form-check-input boolean optional"
                  name="task[is_done]"
                  id="task_is_done"
                  type="checkbox"
                  ref={register}
                />
              </div>
            </div>
          </div>

          <div className="form-group row string optional task_description">
            <label className="col-sm-3 col-form-label string optional">
              Notes
                  </label>
            <div className="col-sm-9">
              <textarea
                className="form-control text optional"
                name="task[description]"
                id="task_description"
                ref={register}
              ></textarea>
            </div>
          </div>
        </div>
      </Collapse>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.cancelEdit()}>
          Annuler
        </Button>
        <input
          type="submit"
          className="btn btn btn-success"
          value="Éditer"
        />
      </Modal.Footer>
    </form>
  )
}