import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import classNames from "classnames";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import Select from "react-select";

import { useForm, Controller } from "react-hook-form";

/* Import Interfaces */
import { TaskCreateInterface } from "./interfaces";

export const TaskCreate = (props: TaskCreateInterface) => {

  const { width, user, date, remaingUnits } = props;
  
  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);

  const handleClose = () => {
    setShow(false);
    setOpen(false);
  }
  const handleShow = () => setShow(true);

  const { register, handleSubmit, setValue, control, watch, errors, setError } = useForm();

  const taskTypes = [
    { value: 'task', label: 'Tâche' },
    { value: 'email', label: 'Email' },
    { value: 'call', label: 'Appel' },
    { value: 'meeting', label: 'Réunion' },
    { value: 'lunch', label: 'Repas' },
    { value: 'other', label: 'Autre' },
  ]

  const onSubmit = (data) => {
    var task = data.task;
    var _data = { task: { ...task, user_id: task.user_id.value, order_ids: task.order_ids.map(o => o.value) } }

    axios
      .post("/api/v1/tasks", _data)
      .then(function (response) {
        props.addTask(response.data);
        setShow(false);
      })
      .catch(function (error) {
        Object.entries(error.response.data).forEach(([name, value]) => {
          setError(`task[${name}]`, { type: "manual", message: value.join(", ") })
        });
      });
  }

  useEffect(() => {
    setIsHovered(props.isHovered);
  }, [props]);

  useEffect(() => {
    async function fetchUsers() {
      const usersResult = await axios('/api/v1/users');
      setUsers(usersResult.data.map(u => { return { value: u.id, label: u.name } }));
    }
    async function fetchOrders() {
      const ordersResult = await axios('/api/v1/orders');
      setOrders(ordersResult.data.map(u => { return { value: u.id, label: u.full_name } }));
    }
    if(show){
      fetchUsers();
      fetchOrders();
    }
  }, [show])

  const isPast = moment(date).isBefore(moment(), "day");

  let classes = classNames("card text-white mx-1 border-0", {
    "bg-success": !isPast,
    "bg-warning": isPast,
  });

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={classes}
        style={{
          cursor: "pointer",
          width: `${width}%`,
          opacity: isHovered || isPast ? "100%" : "0%",
        }}
        onClick={handleShow}
      >
        <div className="card-body p-1 text-center align-items-center d-flex justify-content-center">
          <h6 className="mb-0">
            {isPast ? (
              <i className="fas fa-infinity"></i>
            ) : (
              <i className="fa fa-plus"></i>
            )}
             ({remaingUnits})
          </h6>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Tâche : ajout rapide</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group row string required task_order_ids">
              <label className="col-sm-3 col-form-label string required">
                Prestations <abbr title="required">*</abbr>
              </label>
              <div className={`col-sm-9 ${errors && errors.task && errors.task.order_ids ? 'is-invalid' : ''}`}>
                <Controller
                  as={
                    <Select
                      options={orders}
                      isMulti
                      placeholder="Sélectionner une ou plusieurs prestations"
                      styles={{
                        control: (provided, state) => (errors && errors.task && errors.task.order_ids ? {
                          ...provided, borderColor: '#fd397a',
                        } : provided)
                      }}
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="task[order_ids]"
                  id="task_order_ids"
                />
                {errors && errors.task && errors.task.order_ids && <div className="invalid-feedback">Requis</div>}
              </div>
              
            </div>

            <div className="form-group row string required task_name">
              <label className="col-sm-3 col-form-label string required">
                Tâche <abbr title="required">*</abbr>
              </label>
              <div className="col-sm-9">
                <input
                  className={`form-control ${errors && errors.task && errors.task.name ? 'is-invalid' : ''}`}
                  name="task[name]"
                  id="task_name"
                  ref={register({ required: true })}
                />
                {errors && errors.task && errors.task.name && <div className="invalid-feedback">Requis</div>}
              </div>
            </div>

            <div className="form-group row string optional task_task_type">
              <label className="col-sm-3 col-form-label string optional">
                Type
              </label>
              <div className="col-sm-9">
                <Controller
                  as={
                    <Select
                      placeholder="Sélectionner un type de tâche"
                      options={taskTypes}
                      defaultValue={taskTypes[0]}
                    />
                  }
                  defaultValue={taskTypes[0]}
                  control={control}
                  name="task[task_type]"
                  id="task_task_type"
                />
              </div>
            </div>

            <div className="form-group row string required task_user_id">
              <label className="col-sm-3 col-form-label string required">
                Utilisateur <abbr title="required">*</abbr>
              </label>
              <div className={`col-sm-9 ${errors && errors.task && errors.task.user_id ? 'is-invalid' : ''}`}>
                <Controller
                  as={
                    <Select
                      options={users}
                      placeholder="Sélectionner un utilisateur"
                      defaultValue={user && {value: user.id, label: user.name}}
                      isClearable={true}
                      styles={{
                        control: (provided, state) => (errors && errors.task && errors.task.user_id ? {
                          ...provided, borderColor: '#fd397a',
                        } : provided)
                      }}
                    />
                  }
                  control={control}
                  defaultValue={user && { value: user.id, label: user.name }}
                  rules={{ required: true }}
                  name="task[user_id]"
                  id="task_user_id"
                />
                {errors && errors.task && errors.task.user_id && <div className="invalid-feedback">Requis</div>}
              </div>
            </div>

            <div className="form-group row string required task_due_date">
              <label className="col-sm-3 col-form-label string required">
                Date <abbr title="required">*</abbr>
              </label>
              <div className="col-sm-9">
                <input
                  className={`form-control ${errors && errors.task && errors.task.due_date ? 'is-invalid' : ''}`}
                  name="task[due_date]"
                  id="task_due_date"
                  defaultValue={date}
                  type="date"
                  ref={register}
                />
                {errors && errors.task && errors.task.due_date && <div className="invalid-feedback">{errors.task.due_date.message}</div>}
              </div>
            </div>



            <div className="form-group row string required task_units">
              <label className="col-sm-3 col-form-label string required">
                Unités <abbr title="required">*</abbr>
              </label>

              <div className="col-sm-9">
                <input
                  className={`form-control ${errors && errors.task && errors.task.units ? 'is-invalid' : ''}`}
                  name="task[units]"
                  id="task_units"
                  defaultValue={remaingUnits}
                  type="number"
                  step="any"
                  ref={register}
                />
                {errors && errors.task && errors.task.units && <div className="invalid-feedback">{errors.task.units.message}</div>}
              </div>
            </div>

            <Button
              variant="link"
              onClick={() => setOpen(!open)}
              aria-controls="task-collapse-options"
              aria-expanded={open}
              className="px-0"
            >
              + Options
            </Button>
            <Collapse in={open}>
              <div id="task-collapse-options" className="mt-4">
                <div className="form-group row boolean optional task_is_unshiftable">
                  <label className="col-sm-3">Non décalable</label>
                  <div className="col-sm-9">
                    <div className="form-check">
                      <input
                        className="form-check-input boolean optional"
                        name="task[is_unshiftable]"
                        id="task_is_unshiftable"
                        type="checkbox"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row boolean optional task_is_done">
                  <label className="col-sm-3">Validée</label>
                  <div className="col-sm-9">
                    <div className="form-check">
                      <input
                        className="form-check-input boolean optional"
                        name="task[is_done]"
                        id="task_is_done"
                        type="checkbox"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row string optional task_description">
                  <label className="col-sm-3 col-form-label string optional">
                    Notes
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      className="form-control text optional"
                      name="task[description]"
                      id="task_description"
                      ref={register}
                    ></textarea>
                  </div>
                </div>
              </div>
            </Collapse>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <input
              type="submit"
              className="btn btn btn-success"
              value="Créer"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
