import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import Select from "react-select";
/* Import Interfaces */
import { PlanningInterface, TaskObject, UserObject, AbsenceObject, HolidayObject } from "./interfaces";

/* Import Components */
import { DayRow } from "./dayRow";


// export function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }


// Avoid z-index with fixed table header
const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "11"
  })
};

const Planning = (props: PlanningInterface) => {
  
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate]     = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [tasks, setTasks]         = useState([]);
  
  const [users, setUsers]         = useState([]);
  const [usersCollection, setUsersCollection] = useState([]);
  const [usersSelectValue, setUsersSelectValue] = useState(JSON.parse(localStorage.getItem("usersSelectValue")) || []);

  const [absences, setAbsences] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // TODO : load only new date
  // const prevStartDate = usePrevious(startDate);
  // const prevEndDate   = usePrevious(endDate);

  const usersId = users.map(u => u.id);
  const usersSelectValueId = usersSelectValue ? usersSelectValue.map(u => { return u.value }) : [];

  const requestParams = {
    start_date: startDate,
    end_date: endDate,
    user_ids: usersId
  }

  const fetchUsersCollection = async () => {
    const usersResult = await axios.get('/api/v1/users');
    setUsersCollection(usersResult.data);
    setUsers(usersResult.data.filter(u => usersSelectValueId.includes(u.id)));
  }

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
    fetchUsersCollection();
  }, [props]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await axios.get("/api/v1/plannings/list", { params: requestParams });
    setIsLoading(false);
    setTasks(response.data.tasks);
    setAbsences(response.data.absences);
    setHolidays(response.data.holidays);
  };

  useEffect(() => {
    startDate && endDate && users && fetchData();
  }, [startDate, endDate, users]);

  useEffect(() => {
    localStorage.setItem('usersSelectValue', JSON.stringify(usersSelectValue));
    setUsers(usersCollection.filter(u => usersSelectValueId.includes(u.id)));
  }, [usersSelectValue])

  useEffect(() => {
    setStartDate(moment(selectedDate).subtract(1, "weeks").format("YYYY-MM-DD"));
    setEndDate(moment(selectedDate).add(2, "weeks").format("YYYY-MM-DD"));
  }, [selectedDate])

  const addTask = (task: TaskObject) => {
    setTasks(update(tasks, { $push: [task] }));
  }

  const editTask = (id: string, task: TaskObject) => {
    const index = tasks.findIndex(t => t.id == id);
    setTasks(update(tasks, {[index]: {$set: task }}));
  }

  const removeTask = (id: string) => {
    const index = tasks.findIndex(t => t.id == id);
    setTasks(update(tasks, { $splice: [[index, 1]] }));
  }

  const renderSelectUsers = () => {
    return (
      usersCollection.length > 0 && 
      <div className="mb-3">
        <Select
          styles={selectStyles}
          options={usersCollection.map((u: UserObject) => { return { value: u.id, label: u.name } })}
          placeholder="Sélectionner un ou plusieurs utilisateurs"
          isMulti
          value={usersSelectValue}
          onChange={(usersSelectValue) => setUsersSelectValue(usersSelectValue)}
          isClearable={false}
        />
      </div>
    )
  }

  const renderSelectDate = () => {
    return (
      <div className="input-group mb-3">
        <input
          className="form-control"
          type="date"
          value={selectedDate}
          onChange={e => setSelectedDate(e.target.value)}
        />

        {!moment(selectedDate).isSame(moment(), "day") && 
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" id="button-today" onClick={() => setSelectedDate(moment().format("YYYY-MM-DD"))}>Today</button>
          </div>
        }
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <tr>
        <th style={{ cursor: "pointer", width: "80px" }} className="text-center bg-white py-2 shadow-sm border">
          <button className="btn btn-block" onClick={() => setStartDate(moment(startDate).subtract(1, "weeks").format("YYYY-MM-DD"))}>↑</button>
        </th>
        {users.map((user, i) => {
          return (
            <th className="text-center align-middle bg-white shadow-sm" key={`th-${i}`}>{user.name}</th>
          );
        })}
      </tr>
    )
  }

  const renderFooter = () => {
    return (
      <tr>
        <th style={{ cursor: "pointer" }} colSpan={users.length + 1} className="text-center">
          <button className="btn btn-block" onClick={() => setEndDate(moment(endDate).add(1, "weeks").format("YYYY-MM-DD"))}>↓</button>
        </th>
      </tr>
    );
  }

  const renderDayRows = () => {
    const { dailyHours } = props;

    const days = [];
    const startDay = moment(startDate);
    const endDay = moment(endDate);

    while (startDay.isBefore(endDay) || startDay.isSame(endDay)) {
      const date = startDay.format("YYYY-MM-DD");
      days.push(
        <DayRow 
          key={`day-row-${startDay.format("X")}-${usersId.join("_")}`} 
          date={date}
          selectedDate={selectedDate}
          users={users}
          isLoading={isLoading}
          holidays={holidays.filter((h: HolidayObject) => h.date === date)}
          tasks={tasks.filter((t: TaskObject) => t.due_date === date)}
          absences={absences.filter((a: AbsenceObject) => startDay.isBetween(moment(a.from_date), moment(a.to_date), undefined, "[]"))}
          dailyHours={dailyHours}
          addTask={addTask}
          editTask={editTask}
          removeTask={removeTask}
        />
      );
      startDay.add(1, "days");
    }
    return days;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-9">{renderSelectUsers()}</div>
        <div className="col-sm-3">{renderSelectDate()}</div>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="table-fix-head">
          <table
            className="table table-bordered table-hover table-sm small"
            style={{ tableLayout: "fixed" }}
          >
            <thead>{renderHeader()}</thead>
            <tbody>{renderDayRows()}</tbody>
            <tfoot>{renderFooter()}</tfoot>
          </table>
        </div>
      </DndProvider>
    </div>
  );
}
export default Planning;