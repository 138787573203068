// Class definition
var KTQuilDemos = function () {

  // Private functions
  var demo1 = function () {

    var input_description = jQuery('#wiki_description');

    var quill = new Quill('#wiki-description-editor', {
      modules: {
        toolbar: [
          [{
            header: [1, 2, 3, false]
          }],
          ['bold', 'italic', 'underline', 'link', 'list'],
          ['image', 'code-block']
        ]
      },
      placeholder: 'Type your text here...',
      theme: 'snow'
    });

      
    quill.on('text-change', function (delta, oldDelta, source) {
      input_description.val(quill.root.innerHTML)
    });
      

  }

  return {
    // public functions
    init: function () {
      demo1();
    }
  };
}();

// Initialization
jQuery(document).on('turbolinks:load', function () {
  jQuery(".wiki-form-fields").each(function(){
    KTQuilDemos.init();
  })
});