import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";

/* Import Interfaces */
import { DayRowInterface } from "./interfaces";

/* Import Components */
import { DayCell } from "./dayCell";

moment.locale('fr')

export const DayRow = (props: DayRowInterface) => {
  
  const [isLoading, setIsLoading]   = useState(true);
  const [isLoaded, setIsLoaded]     = useState(false);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props]);

  useEffect(() => {
    if(!isLoading && !isLoaded) {
      setIsLoaded(true); 
    }
  }, [isLoading]);

  const { users, date, selectedDate, dailyHours, holidays, tasks, absences, addTask, editTask, removeTask } = props;

  function renderDayCells() {

    return users.map((user, i) => (
      <DayCell 
        key={`day-cell-${date}-${user.id}-${i}`} 
        user={user} 
        date={date} 
        isLoading={isLoading && !isLoaded}
        holidays={holidays}
        tasks={tasks && tasks.filter(elt => elt.user_id == user.id)}
        absences={absences && absences.filter(elt => elt.user_id == user.id)}
        dailyHours={dailyHours}
        addTask={addTask}
        editTask={editTask}
        removeTask={removeTask}
      />
    ));
  }

  const isToday = moment(date).isSame(moment(), "day");
  const isSelectedDate = moment(date).isSame(moment(selectedDate));
  const isWeekend = moment(date).day() === 0 || moment(date).day() === 6;
  const isHolidays = holidays && holidays.length > 0;

  const classes = classNames("align-middle", {
    "table-success": isToday,
    "table-info": !isToday && isSelectedDate,
    "table-danger": isWeekend || isHolidays
  });

  return (
    <tr className={classes}>
      <td className="align-middle text-nowrap text-center">{date && moment(date).format("ddd DD/MM")}</td>
      {renderDayCells()}
    </tr>
  )
}