// Class definition
var KTSelect2 = function () {
  // Private functions
  var demos = function () {
    // basic
    $('#kt_select2_deal').select2({
      placeholder: "Select a deal"
    });

    $('#kt_select2_company').select2({
      placeholder: "Select a company"
    });

    $('#kt_select2_contact').select2({
      placeholder: "Select a contact"
    });

    $('#kt_select2_product').select2({
      placeholder: "Select a product"
    });

    $('#kt_select2_project').select2({
      placeholder: "Select a project"
    });

    $('#kt_select2_invoice').select2({
      placeholder: "Select a invoice"
    });

    $('#kt_select2_user').select2({
      placeholder: "Select an user",
      allowClear: true,
    });

    $('#kt_select2_contract_type').select2({
      placeholder: "Select a contract type",
      tags: true
    })

    $('#kt_select2_deal_source').select2({
      placeholder: "Select a source",
      tags: true
    });

    $('#kt_select2_deal_campaign').select2({
      placeholder: "Select a campaign",
      tags: true
    })

    $('#kt_select2_expense_provider').select2({
      placeholder: "Select a provider",
      tags: true
    });

    $('#kt_select2_expense_tax_horsepower').select2({
      placeholder: "Select a tax horsepower",
    });

    $('#kt_select2_task_order_ids').select2({
      placeholder: "Select orders",
      tags: true
    });

    $('#kt_select2_payment_method').select2({
      placeholder: "Select a payment method",
      tags: true
    });

    $('#kt_select2_absence_type').select2({
      placeholder: "Select an absence type",
      tags: true
    });

  }

  var modalDemos = function () {
    //
  }

  // Public functions
  return {
    init: function () {
      demos();
      modalDemos();
    }
  };
}();

// Initialization
jQuery(document).on('turbolinks:load', function () {
  KTSelect2.init();
});