import { init_company_form, init_company_search } from './companies';

function update_contact_name(elem) {
  var container = elem.parents('form');
  var first_name = jQuery(container).find('input#contact_first_name').val();
  var last_name = jQuery(container).find('input#contact_last_name').val();
  jQuery(container).find('input#contact_name').val(first_name + ' ' + last_name.toUpperCase());
}

var init_contact_form = () => {
  jQuery('.contact-form-fields').on('blur keyup change', 'input#contact_first_name, input#contact_last_name', (event) => {
    update_contact_name(jQuery(event.target));
  });
}

jQuery(document).on('turbolinks:load', (event) => {
  init_contact_form();
  jQuery('#referentable_company').on('cocoon:after-insert', (event) => {
    init_company_form();
    init_company_search();
  });
});

export { init_contact_form, init_company_search };