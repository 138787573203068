import React, { Component, useState, useEffect } from "react";

/* Import Interfaces */
import { CoffeeInterface } from "./interfaces";

export const Coffee = (props: CoffeeInterface) => {

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(props.width);
  }, [props]);

  return (
      <div
        className="card text-white bg-warning mx-1"
        style={{ cursor: "pointer", width: `${width}%` }}
      >
        <div className="card-body p-1 text-center">
          <h6 className="mb-0">
          <i className="flaticon-infinity"></i>
          </h6>
          <span> ? </span>
        </div>
      </div>
  );
};
