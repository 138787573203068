"use strict";

var KTChartJSForecastDemo = function () {

  var accounting_demo1 = function(){
    var ctx = $('#kt_accounting_chartjs_1');
    if(ctx.length){
      $.ajax({
        url: '/forecasts/accounting_demo1.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setAccountingDemo1Chart(ctx, data);
        }
      });
    }
  }

  var accounting_demo2 = function (){
    var ctx = $('#kt_accounting_chartjs_2');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/accounting_demo2.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setAccountingDemo2Chart(ctx, data);
        }
      });
    }
  }

  var accounting_demo3 = function () {
    var ctx = $('#kt_accounting_chartjs_3');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/accounting_demo3.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setAccountingDemo3Chart(ctx, data);
        }
      });
    }
  }

  var sales_demo1 = function () {
    var ctx = $('#kt_sales_chartjs_1');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/sales_demo1.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setSalesDemo1Chart(ctx, data);
        }
      });
    }
  }
  var tracks_orders_per_month_chart = function () {
    var ctx = $('#kt_tracks_orders_per_month_chart');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/tracks_orders_per_month.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setTracksOrdersPerMonthChart(ctx, data);
        }
      })
    }
  }

  var setTracksOrdersPerMonthChart = function (ctx, data) {
    var datasets = [];
    var labels = data.ticks;

    data.orders.forEach(function (d, index) {
      datasets.push({
        label: d.name,
        data: d.data,
        backgroundColor: Object.values(window.chartColors)[index % 7]
      })
    });

    var tracksOrdersPerMonthChartData = {
      labels: labels,
      datasets: datasets,
    }

    var tracksOrdersPerMonthChart = new Chart(ctx, {
      type: 'line',
      data: tracksOrdersPerMonthChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0
            }
          }]
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
      }
    });
  }

  var tracks_orders_standard_per_month_chart = function () {
    var ctx = $('#kt_tracks_orders_standard_per_month_chart');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/tracks_orders_standard_per_month.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setTracksOrdersStandardPerMonthChart(ctx, data);
        }
      })
    }
  }

  var setTracksOrdersStandardPerMonthChart = function(ctx, data) {
    var datasets = [];
    var labels = data.ticks;

    data.orders.forEach(function(d, index) {
      datasets.push({
        label: d.name,
        data: d.data,
        backgroundColor: Object.values(window.chartColors)[index % 7]
      })
    });

    var tracksOrdersStandardPerMonthChartData = {
      labels: labels,
      datasets: datasets,
    }

    var tracksOrdersStandardPerMonthChart = new Chart(ctx, {
      type: 'line',
      data: tracksOrdersStandardPerMonthChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0
            }
          }]
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
      }
    });
  }

  var tracks_orders_recurring_per_month_chart = function () {
    var ctx = $('#kt_tracks_orders_recurring_per_month_chart');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/tracks_orders_recurring_per_month.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setTracksOrdersRecurringPerMonthChart(ctx, data);
        }
      })
    }
  }

  var setTracksOrdersRecurringPerMonthChart = function (ctx, data) {
    var datasets = [];
    var labels = data.ticks;

    data.orders.forEach(function (d, index) {
      datasets.push({
        label: d.name,
        data: d.data,
        backgroundColor: Object.values(window.chartColors)[index % 7]
      })
    });

    var tracksOrdersRecurringPerMonthChartData = {
      labels: labels,
      datasets: datasets,
    }

    var tracksOrdersRecurringPerMonthChart = new Chart(ctx, {
      type: 'line',
      data: tracksOrdersRecurringPerMonthChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0
            }
          }]
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
      }
    });

  }

  var sales_podium_chart = function () {
    var ctx = $('#kt_sales_podium_chart');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/sales_podium.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setSalesPodiumChart(ctx, data);
        }
      })
    }
  }

  var setSalesPodiumChart = function(ctx, data) {
    var datasets = [];
    var labels = data.ticks;

    data.podiums.forEach(function(d, index) {
      datasets.push({
        label: d.name,
        data: d.data,
        backgroundColor: Object.values(window.chartColors)[index % 7]
      })
    });

    var salesPodiumChartData = {
      labels: labels,
      datasets: datasets,
    }

    var salesDealsCreatedWonLostPerMonthChart = new Chart(ctx, {
      type: 'bar',
      data: salesPodiumChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
      }
    });
  }

  var sales_deals_created_won_lost_per_month_chart = function () {
    var ctx = $('#kt_sales_deals_created_won_lost_per_month_chart');
    if (ctx.length) {
      $.ajax({
        url: '/forecasts/sales_deals_created_won_lost_per_month.json',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          setSalesDealsCreatedWonLostPerMonthChart(ctx, data);
        }
      })
    }
  }

  var setSalesDealsCreatedWonLostPerMonthChart = function(ctx, data) {
    var datasets = [];
    var labels = data.map(o => o.month);

    datasets = [{
      label: 'Deals Créés',
      backgroundColor: window.chartColors.blue,
      stack: 'Stack 0',
      data: data.map(o => o.created)
    }, {
      label: 'Deals Gagnés',
      backgroundColor: window.chartColors.green,
      stack: 'Stack 0',
      data: data.map(o => o.won)
    }, {
      label: 'Deals Perdus',
      backgroundColor: window.chartColors.red,
      stack: 'Stack 0',
      data: data.map(o => o.lost)
    }];

    var salesDealsCreatedWonLostPerMonthChartData = {
      labels: labels,
      datasets: datasets,
    }

    var salesDealsCreatedWonLostPerMonthChart = new Chart(ctx, {
      type: 'bar',
      data: salesDealsCreatedWonLostPerMonthChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 1
            }
          }]
        }
      }
    });
    

  }




  var setAccountingDemo1Chart = function(ctx, data) {
    var datasets = [];
    var labels = [];  
    data.forEach(function (d, index) {
      var label = Object.keys(d)[0];
      var hash = Object.values(d)[0];
      labels = Object.keys(hash);
      var values = Object.values(hash);
      datasets.push({
        label: label,
        data: values,
        backgroundColor: Object.values(window.chartColors)[index % 7],
        borderColor: Object.values(window.chartColors)[index % 7],
        borderWidth: 3,
        fill: false,
        hidden: index <= data.length - 3
      });
    });

    var demo1AccountingChartData = {
      labels: labels,
      datasets: datasets
    };

    var demo1AccountingChart = new Chart(ctx, {
      type: 'line',
      data: demo1AccountingChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: false,
            }
          }],
          yAxes: [{
            ticks: { beginAtZero: true },
            display: true,
            scaleLabel: {
              display: false,
            }
          }]
        }
      }
    });
  }

  var setAccountingDemo2Chart = function (ctx, data) {
    var datasets = [];
    var labels = data.map(o => o.period);
    
    datasets.push({
      yAxisID: 'Growth',
      type: "line",
      label: "Growth",
      data: data.map(o => o.growth),
      backgroundColor: Object.values(window.chartColors)[2],
      borderColor: Object.values(window.chartColors)[2],
      borderWidth: 3,
      fill: false,
    });

    datasets.push({
      yAxisID: 'Revenues',
      type: "bar",
      label: "Revenues",
      data: data.map(o => o.revenues),
      backgroundColor: Object.values(window.chartColors)[0],
      borderColor: Object.values(window.chartColors)[0],
      borderWidth: 3
    });

    datasets.push({
      yAxisID: 'Revenues',
      type: "bar",
      label: "Previsional",
      data: data.map(o => o.previsional),
      backgroundColor: Object.values(window.chartColors)[1],
      borderColor: Object.values(window.chartColors)[1],
      borderWidth: 3
    });

    var demo2AccountingChartData = {
      labels: labels,
      datasets: datasets
    };

    var demo2AccountingChart = new Chart(ctx, {
      type: 'bar',
      data: demo2AccountingChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              if(tooltipItem.datasetIndex === 0){
                return new Intl.NumberFormat('fr-Fr').format(tooltipItem.yLabel) + "%";
              }else{
                return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel);
              }
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: false,
            }
          }],
          yAxes: [{
            id: 'Revenues',
            ticks: { beginAtZero: true },
            display: true,
            stacked: true,
            position: 'left',
            scaleLabel: {
              display: false,
            }
          },
          {
            id: 'Growth',
            ticks: { beginAtZero: true },
            display: true,
            position: 'right',
            scaleLabel: {
              display: false,
            }
          }]
        }
      }
    });
  }

  var setAccountingDemo3Chart = function (ctx, data) {
    var datasets = [];
    var labels = data.map(o => o.date);
    datasets.push({
      type: "bar",
      label: "Revenues out",
      data: data.map(o => o.amount),
      backgroundColor: Object.values(window.chartColors)[0],
      borderColor: Object.values(window.chartColors)[0],
      borderWidth: 3
    });

    var demo3AccountingChartData = {
      labels: labels,
      datasets: datasets
    };

    var demo3AccountingChart = new Chart(ctx, {
      type: 'bar',
      data: demo3AccountingChartData,
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        tooltips: {
          bodyAlign: 'right',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(tooltipItem.yLabel)
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: false,
            }
          }],
          yAxes: [{
            ticks: { beginAtZero: true },
            display: true,
            scaleLabel: {
              display: false,
            }
          }]
        }
      }
    });
  }

  var setSalesDemo1Chart = function (ctx, data) {
    var datasets = [];
    var labels = [];  

  }

  return {
    init: function () {
      accounting_demo1();
      accounting_demo2();
      accounting_demo3();
      sales_demo1();
      sales_deals_created_won_lost_per_month_chart();
      sales_podium_chart();
      tracks_orders_per_month_chart();
      tracks_orders_standard_per_month_chart();
      tracks_orders_recurring_per_month_chart();
    }
  };
}();

jQuery(document).on('turbolinks:load', (event) => {
  KTChartJSForecastDemo.init();
});
