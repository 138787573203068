import React, { Component, useState, useEffect } from "react";

/* Import Interfaces */
import { HolidaysInterface } from "./interfaces";

export const Holidays = (props: HolidaysInterface) => {

  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    setHolidays(props.holidays);
  }, [props]);

  return (
    <div className="flex-grow-1 text-center text-danger">{holidays.map(h => h.name).join(', ')}</div>
  );
}
