import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import classNames from "classnames";
import { useDrop } from "react-dnd";


/* Import Interfaces */
import { DayCellInterface } from "./interfaces";

/* Import Components */
import { Holidays } from "./holidays";
import { Absences } from "./absences";
import { Loading } from "./loading";
import { TaskList } from "./taskList";

export const DayCell = (props: DayCellInterface) => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props]);

  const { user, date, dailyHours, holidays, absences, tasks, addTask, editTask, removeTask } = props;


  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "TASK",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      axios
        .put(`/api/v1/tasks/${item.task.id}`, {
          task: {
            user_id: user.id,
            due_date: date
          }
        })
        .then(function (response) {
          editTask(item.task.id, response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });

    
    },
    canDrop: (item, monitor) => {
      return taskable && !tasks.map((t) => t.id).includes(item.task.id)
    }
  });

  if(isLoading) {
    return (
      <td><Loading /></td>
    )
  }

  const isWeekend = moment(date).day() === 0 || moment(date).day() === 6;
  const isHolidays = holidays && holidays.length > 0;
  const haveAbsences = absences && absences.length > 0;
  const isActive = isOver && canDrop;

  const sumUnits = tasks ? tasks.reduce((sum: number, task: TaskObject) => sum + parseFloat(task.units), 0) : 0;
  const remainingUnits = dailyHours - sumUnits;

  const taskable = (
    !(moment(date).day() === 0 ||
    moment(date).day() === 6 ||
    isHolidays ||
    haveAbsences)
  );

  let classes = classNames("p-0 align-middle", {
    "table-warning": haveAbsences, // Absent
    "table-info": isActive, // Si hovered
    "bg-danger": remainingUnits < 0 // Si journée à plus de dailyHours
  });

  return (
    <td
      className={classes}
      ref={drop}
    >
      {isHolidays && <Holidays holidays={holidays} />}
      {isWeekend && <div className="text-danger text-center">Week-end</div>}
      {!isWeekend && haveAbsences && <Absences absences={absences} />}
      {taskable && (
        <TaskList
          date={date}
          tasks={tasks}
          addTask={addTask}
          editTask={editTask}
          removeTask={removeTask}
          user={user}
          dailyHours={dailyHours}
        />
      )}
    </td>
  );
}
