// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'jquery'
window.$ = window.jQuery = require('jquery');

import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

// import('src/plugins')
require("trix")
require("@rails/actiontext")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

require.context('../media', true)

// COCOON
import 'cocoon-js';

// KEEN

require("bootstrap");

window.Sticky = require("sticky-js");
window.Cookies = require("js-cookie");
window.Popper = require("popper.js");
window.moment = require("moment");

// Perfect-Scrollbar
require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");

// Tooltips
import Tooltip from "tooltip.js";
window.Tooltip = Tooltip;

// Select2
require("select2/dist/css/select2.css");
require("select2");

// Font Icons
require("../plugins/line-awesome/css/line-awesome.css");
require("../plugins/flaticon/flaticon.css");
require("../plugins/flaticon2/flaticon.css");
require("@fortawesome/fontawesome-free/css/all.min.css");

// Fullcalendar
require("@fullcalendar/core/main.css");
require("@fullcalendar/daygrid/main.css");
require("@fullcalendar/list/main.css");
require("@fullcalendar/timegrid/main.css");

window.FullCalendar = require("@fullcalendar/core");
window.FullCalendarDayGrid = require("@fullcalendar/daygrid");
window.FullCalendarGoogleCalendar = require("@fullcalendar/google-calendar");
window.FullCalendarInteraction = require("@fullcalendar/interaction");
window.FullCalendarList = require("@fullcalendar/list");
window.FullCalendarTimeGrid = require("@fullcalendar/timegrid");

// jQuery UI & sortable
require("jquery-ui");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/themes/base/sortable.css");

// Quill
require("quill/dist/quill.snow.css");
window.Quill = require("quill");

// Chart.js
window.Chart = require("chart.js");
window.chartColors = {
  blue: "rgb(54, 162, 235)",
  green: "rgb(75, 192, 192)",
  red: "rgb(255, 99, 132)",
  yellow: "rgb(255, 205, 86)",
  orange: "rgb(255, 159, 64)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

// Core Plugins
window.KTUtil = require("../src/global/components/base/util");
window.KTApp = require("../src/global/components/base/app");
window.KTAvatar = require("../src/global/components/base/avatar");
window.KTHeader = require("../src/global/components/base/header");
window.KTMenu = require("../src/global/components/base/menu");
window.KTOffcanvas = require("../src/global/components/base/offcanvas");
window.KTPortlet = require("../src/global/components/base/portlet");
window.KTScrolltop = require("../src/global/components/base/scrolltop");
window.KTToggle = require("../src/global/components/base/toggle");
require("../src/global/components/base/datatable/core.datatable");
require("../src/global/components/base/datatable/datatable.checkbox");

// Layout Scripts
window.KTLayout = require("../src/global/layout/layout");
window.KTLib = require("../src/global/layout/lib");
require("../src/global/layout/offcanvas-panel");
require("../src/global/layout/quick-search");

// CUSTOM

require('../src/pages/utils')

require('../src/pages/select2')
require('../src/pages/cocoon')

require('../src/pages/absences')
require('../src/pages/activities')
require('../src/pages/companies')
require('../src/pages/contacts')
require('../src/pages/deals')
require('../src/pages/expenses')
require('../src/pages/forecasts')
require('../src/pages/invoices')
require('../src/pages/tasks')
require('../src/pages/settings')
require('../src/pages/wikis')

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
