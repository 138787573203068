var setBillingAddress = (elem) => {
  var container = elem.parents('form')
  var company_id = jQuery(container).find("select#kt_select2_company").val()

  var billing_address = jQuery('#invoice_billing_address');
  billing_address.prop('disabled', true);

  jQuery.ajax({
    url: `/companies/${company_id}.json`,
    type: "GET",
    success: (data) => {
      billing_address.prop('disabled', false);
      billing_address.val(data.address)
    }
  });
}

var updateInvoice = (elem) => {
  var subtotal_ht = 0.0
  var total_ttc = 0.0
  var taxes = {}

  jQuery("#items .nested-fields").each(function () {
    if (jQuery(this).css('display') != 'none') {
      var container = jQuery(this)
      var unit_amount = jQuery(container).find("input.item_unit_amount").val()
      var qty = jQuery(container).find("input.item_quantity").val()
      var vat = jQuery(container).find("select.item_tax_type").val()
      var discount_amount = jQuery(container).find("input.item_discount_amount").val()
      var discount_type = jQuery(container).find("select.item_discount_type").val()

      if (!unit_amount || !jQuery.isNumeric(unit_amount)) {
        unit_amount = 0;
      }
      if (!qty || !jQuery.isNumeric(qty)) {
        qty = 0;
      }
      if (!vat || !jQuery.isNumeric(vat)) {
        vat = 0;
      }
      if (!discount_amount || !jQuery.isNumeric(discount_amount)) {
        discount_amount = 0;
      }

      line_total = (parseFloat(unit_amount) * parseFloat(qty)).toFixed(2);
      if (discount_type == "per") {
        discount_amount = line_total * (parseFloat(discount_amount) / 100.0);
      } else {
        discount_amount = parseFloat(discount_amount);
      }
      line_total -= discount_amount;
      line_tax = (parseFloat(line_total) * parseFloat(vat)).toFixed(2);

      if (!taxes[vat] || !jQuery.isNumeric(taxes[vat])) {
        taxes[vat] = 0;
      }
      taxes[vat] = parseFloat(taxes[vat]) + parseFloat(line_tax);
      line_ttc = (parseFloat(line_total) + parseFloat(line_tax)).toFixed(2);

      jQuery(container).find(".item_line_total").val(line_total.toFixed(2));

      subtotal_ht = parseFloat(subtotal_ht) + parseFloat(line_total);
      total_ttc = parseFloat(total_ttc) + parseFloat(line_ttc);
    }
    jQuery("#invoice_line_total").html(subtotal_ht.toFixed(2).replace(".", ","))
    jQuery("#invoice_amount").html(total_ttc.toFixed(2).replace(".", ","))
    jQuery("#invoice_taxes").html("")

    for (let [k, v] of Object.entries(taxes)) {
      if (k != "0.0") {
        jQuery("#invoice_taxes").append('<div><span> TVA ' + (k * 100).toFixed(2).replace(".", ",") + '% : </span><span> ' + v.toFixed(2).replace(".", ",") + '</span><span> €</span></div>')
      }
    }
  });
}

var updateItemNameSuggestions = (elem) => {
  var container = elem.parents('.nested-fields');
  var order_id = elem.val();
  var datalist = container.find("#item-name-suggestions");
  var item_name = container.find(".item_name");

  item_name.val('');
  datalist.empty();
  if(order_id) {
    jQuery.ajax({
      url: `/api/v1/orders/${order_id}/item_name_suggestions`,
      type: "GET",
      success: (data) => {
        for (var i = 0; i < data.length; i++) {
          datalist.append("<option value='" + data[i] + "'></option>");
        }
      }
    });
  }
}

var init_invoice_form = () => {
  jQuery('.invoice-form-fields').on('change', 'select#kt_select2_company', (event) => {
    setBillingAddress(jQuery(event.target));
  });
  jQuery('.invoice-form-fields').on('blur keyup change', 'input.item_unit_amount, input.item_quantity, select.item_tax_type, input.item_discount_amount, select.item_discount_type, input#invoice_discount_amount, select#invoice_discount_type', (event) => {
    updateInvoice(jQuery(event.target));
  });

  jQuery('.invoice-form-fields').on('blur keyup change', 'select.item_order_id', (event) => {
    updateItemNameSuggestions(jQuery(event.target));
  })

}

jQuery(document).on('ready turbolinks:load', (event) => {
  init_invoice_form();
  updateInvoice(jQuery(this));
  jQuery('#items').on('cocoon:after-insert', (event) => {
    updateInvoice(jQuery(this));
  });
})