"use strict";

var KTTasksCalendar = function () {

  return {
    //main function to initiate the module
    init: function () {

      var userIds = getAllUrlParams().user_ids;
      var hasUserIds = userIds != undefined;

      var todayDate = moment().startOf('day');
      var TODAY = todayDate.format('YYYY-MM-DD');

      var calendarEl = document.getElementById('kt_tasks_calendar');

      if (calendarEl != null) {
        var calendar = new FullCalendar.Calendar(calendarEl, {
          locale: 'fr',
          plugins: ['interaction', 'dayGrid', 'timeGrid', 'list'],
          timeZone: 'Europe/Paris',
          isRTL: KTUtil.isRTL(),
          header: {
            left: 'prev,next today',
            center: 'title',
            // right: 'dayGridMonth,timeGridWeek,timeGridDay'
            right: 'dayGridMonth'
          },

          height: 800,
          contentHeight: 780,
          aspectRatio: 3,  // see: https://fullcalendar.io/docs/aspectRatio

          nowIndicator: true,
          now: TODAY + 'T09:25:00', // just for demo

          views: {
            dayGridMonth: { buttonText: 'month' },
            timeGridWeek: { buttonText: 'week' },
            timeGridDay: { buttonText: 'day' }
          },

          defaultView: 'dayGridMonth',
          defaultDate: TODAY,

          editable: true,
          eventLimit: true, // allow "more" link when too many events
          navLinks: true,

          eventSources: [
            {
              url: hasUserIds ? `/tasks.json?user_ids=${userIds}` : '/tasks.json',
            }
          ],

          eventDataTransform: function (eventData) {
            var ro = new Object();
            ro.id = eventData.id;
            ro.url = eventData.url;
            ro.start = eventData.due_date;
            ro.end = eventData.due_date;
            ro.title = eventData.name;
            ro.description = '<h5>' + eventData.orders.map(order => order.full_name).join(', ') + '</h5><h6>' + eventData.name + '</h6><h6>' + eventData.units + '</h6><b>' + eventData.user.name + '</b><p class="mb-0">' + eventData.description + '</p>';
            // ro.description = '<h5>' + eventData.deal.name + '</h5><h6>' + eventData.name + '</h6><b>' + eventData.user.name + '</b><p>' + eventData.description + '</p>';
            // ro.description = eventData.description
            if (eventData.is_done) {
              ro.className = "fc-event-success";
            } else if (eventData.is_late) {
              ro.className = "fc-event-danger";
            }

            ro.sourceObject = eventData;
            // console.log("converted event", ro);
            return ro;
          },

          eventRender: function (info) {
            var element = jQuery(info.el);
            if (info.event.extendedProps && info.event.extendedProps.description) {
              if (element.hasClass('fc-day-grid-event')) {
                element.data('content', info.event.extendedProps.description);
                element.data('placement', 'top');
                KTApp.initPopover(element);
              } else if (element.hasClass('fc-time-grid-event')) {
                element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
              } else if (element.find('.fc-list-item-title').lenght !== 0) {
                element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
              }
            }
          },

          eventDrop: function (info) {
            $.ajax({
              type: 'PATCH',
              url: info.event.url,
              data: {
                authenticity_token: $('[name="csrf-token"]')[0].content,
                task: { due_date: info.event.start.toISOString() }
              },
              dataType: 'JSON'
            }).done(function (data) {
              // console.log("done")
            }).fail(function (data) {
              info.revert();
            });
          },
          
          dateClick: function (info) {
            Turbolinks.visit('/tasks/new?due_date=' + info.dateStr)
          }

        });
        calendar.render();
      }
    },

    clear: function () {
      jQuery('#kt_tasks_calendar').html('')
    }
  };
}();


jQuery(document).on("turbolinks:load", () => {
  KTTasksCalendar.init();
});

jQuery(document).on("turbolinks:before-cache", () => {
  KTTasksCalendar.clear();
});