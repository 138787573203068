import React, { Component, useState, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";
import { useDrag } from "react-dnd";
import axios from "axios";

import { TaskForm } from "./taskForm";

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

/* Import Interfaces */
import { TaskInterface } from "./interfaces";


const TASK_TYPE_ICON = {
  task: 'la la-bookmark',
  email: 'la la-envelope',
  call: 'la la-phone',
  meeting: 'la la-group',
  lunch: 'la la-cutlery',
  other: 'la la-sticky-note',
}

const TASK_TYPE_TXT = {
  task: 'Tâche',
  email: 'Email',
  call: 'Appel',
  meeting: 'Réunion',
  lunch: 'Repas',
  other: 'Autre',
}

export const Task = (props: TaskInterface) => {

  const { id, task, width, date, removeTask, editTask } = props;

  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false)

  const [{ opacity }, drag] = useDrag({
    item: { task, type: "TASK" },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
    // end: (item, monitor) => {
    //   if (monitor.didDrop()) { }
    // }
  });

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
  } 
  const handleShow = () => setShow(true);
  const handleEdit = () => setIsEditing(true);
  const cancelEdit = () => setIsEditing(false);

  const handleDelete = () => {
    if (confirm("Êtes-vous sûr(e) de vouloir supprimer cette tâche ?")) {
      axios
        .delete(`/api/v1/tasks/${id}`)
        .then(function (response) {
          removeTask(id);
          setShow(false);
        })
        .catch(function (error) {
          Object.entries(error.response.data).forEach(([name, value]) => {
            setError(`task[${name}]`, { type: "manual", message: value.join(", ") })
          });
        });
    }
  };

  const handleDone = () => {
    axios
      .put(`/api/v1/tasks/${task.id}`, {
        task: {
          is_done: !task.is_done,
        }
      })
      .then(function (response) {
        editTask(id, response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const isPast = moment(date).isBefore(moment(), "day");

  let classes = classNames("card mx-1 text-white border-0", {
    "bg-info": !isPast && !task.is_done,
    "bg-danger": isPast && !task.is_done,
    "bg-success": task.is_done,
    "bg-primary": task.is_unshiftable
  });

  const renderShowModalBody = () => {
    return (
      <>
      <Modal.Body>
        <table className="table table-borderless table-sm">
          <tbody>
            <tr>
              <th className="text-nowrap pr-3" width="1">
                #
                </th>
              <td className="text-center" width="1"></td>
              <td>{task.id}</td>
            </tr>
            <tr>
              <th className="text-nowrap pr-3" width="1">
                Date
                </th>
              <td className="text-center" width="1"></td>
              <td>{moment(task.due_date).format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <th className="text-nowrap pr-3" width="1">
                Prestations
                </th>
              <td className="text-center" width="1"></td>
              <td>
                <ul className="list-unstyled mb-0">
                  {task && task.orders && task.orders.map((order, i) => {
                    return (
                      <li key={`task-orders-${i}`}><a href={order.url}>{order.full_name}</a></li>
                    )
                  })}
                </ul>
              </td>
            </tr>

            <tr>
              <th className="text-nowrap pr-3" width="1">
                Type
                </th>
              <td className="text-center" width="1"><i className={`${TASK_TYPE_ICON[task.task_type]}`} /></td>
              <td>{TASK_TYPE_TXT[task.task_type]}</td>
            </tr>
            <tr>
              <th className="text-nowrap pr-3" width="1">
                Unités
                </th>
              <td className="text-center" width="1"></td>
              <td>{task.units}</td>
            </tr>

            <tr>
              <th className="text-nowrap pr-3" width="1">
                User
                </th>
              <td className="text-center" width="1"></td>
              <td>
                {task && task.user && <a href={task.user.url}>{task.user.name}</a>}
              </td>
            </tr>

            <tr>
              <th className="text-nowrap pr-3" width="1">
                Validée ?
                </th>
              <td className="text-center" width="1">
                {task.is_done && <i className="fas fa-check text-success" />}
                {!task.is_done && <i className="fas fa-times text-danger" />}
              </td>
              <td className="align-middle">
                {task.is_done && <button className="btn btn-link btn-sm p-0" onClick={() => handleDone()}>Annuler</button>}
              </td>
            </tr>


            <tr>
              <th className="text-nowrap pr-3" width="1">
                Description
                </th>
              <td className="text-center" width="1"></td>
              <td>{task.description}</td>
            </tr>
            <tr>
              <td className="py-3" colSpan="3"></td>
            </tr>

          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            {!task.is_done &&
              <Button variant="outline-danger" onClick={() => handleDelete()}>
                <i className="far fa-trash-alt pr-0" />
              </Button>
            }
            {!task.is_done &&
              <ButtonGroup>
                <Button variant="success" onClick={() => handleDone()}>
                  <i className="fa fa-check" />
                    Valider
                  </Button>
                <Button variant="info" onClick={() => handleEdit()}>
                  <i className="fas fa-pencil-alt"></i>
                    Éditer
                  </Button>
              </ButtonGroup>
            }
          </div>
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
          </Button>
      </Modal.Footer>
      </>
    )
  }

  return (
    <>
      <div
        className={classes}
        ref={drag}
        style={{
          cursor: "grab",
          opacity,
          width: `${width}%`,
          transform: "translate(0, 0)", // to avoid square corners transparent when dragging rounded-corner element
        }}
        onClick={handleShow}
      >
        <div className="card-body px-2 py-1">
          <h6 className="mb-1 text-truncate">
            <i className={`${TASK_TYPE_ICON[task.task_type]} mr-1`} />
            {task.name}
          </h6>
          <p className="mb-0 text-truncate">{task.orders && task.orders.map(o => o.full_name).join(', ')}</p>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><i className={`${TASK_TYPE_ICON[task.task_type]} mr-1`} />{task.name}</Modal.Title>
        </Modal.Header>
        {isEditing && <TaskForm id={id} task={task} editTask={editTask} cancelEdit={cancelEdit}/>}
        {!isEditing && renderShowModalBody()}
      </Modal>
    </>
  );
}
