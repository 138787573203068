import React, { Component } from "react";

/* Import Interfaces */
import { LoadingInterface } from "./interfaces";

export const Loading = (props: LoadingInterface) => {
  return (
    <div className="progress shadow-sm m-1">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow={100}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width: "100%" }}
      ></div>
    </div>
  );
}
