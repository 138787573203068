import { init_contact_form } from './contacts';


function init_company_search() {
  $("#company_search").select2({
    placeholder: "Search for a company",
    allowClear: true,
    ajax: {
      url: function (params) {
        return `https://entreprise.data.gouv.fr/api/sirene/v1/full_text/${params.term}`
      },
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: data.etablissement,
          pagination: {
            more: (params.page * 10) < data.total_results
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    minimumInputLength: 1,
    templateResult: formatCompany,
    templateSelection: function (item, container) {
      setCompanyInfos(item, container);
      return item.nom_raison_sociale || item.text;
    }
  });
}

function formatCompany(company) {
  if (company.loading) return company.text;

  var jQuerycontainer = $(
    "<div class='select2-result-company clearfix'>" +
    "<div class='select2-result-company__meta'>" +
    "<div class='select2-result-company__title'></div>" +
    "<div class='select2-result-company__address small'></div>" +
    "<div class='select2-result-company__siret small'>SIRET : </div>" +
    "</div>" +
    "</div>"
  );

  jQuerycontainer.find(".select2-result-company__title").text(company.nom_raison_sociale);
  jQuerycontainer.find(".select2-result-company__address").append(company.geo_adresse);
  jQuerycontainer.find(".select2-result-company__siret").append(company.siret);

  return jQuerycontainer;
}

function setCompanyInfos(data, container) {
  if (container !== undefined) {
    container = container.parents('form')
    $(container).find('input#company_name').val(data.l1_normalisee);
    $(container).find('input#company_street1').val(data.l4_normalisee).trigger("change");
    $(container).find('textarea#company_street2').val(data.l5_normalisee).trigger("change");
    $(container).find('input#company_city').val(data.libelle_commune).trigger("change");
    $(container).find('input#company_zipcode').val(data.code_postal).trigger("change");
    $(container).find('select#company_country').val("FR").trigger("change");

    $(container).find("input#company_company_type").val(data.libelle_nature_juridique_entreprise);
    $(container).find('input#company_siret').val(data.siret);
    $(container).find('input#company_siren').val(data.siren);
    $(container).find("input#company_naf_code").val(data.activite_principale_entreprise);

    $.get(`https://entreprise.data.gouv.fr/api/sirene/v1/siren/${data.siren}`, function (d) {
      $(container).find("input#company_tax_number").val(d.numero_tva_intra);
    });

  }
}


function update_company_address(elem) {
  var container = elem.parents('form')

  var street1 = jQuery(container).find('input#company_street1').val()
  var street2 = jQuery(container).find('textarea#company_street2').val()
  var city = jQuery(container).find('input#company_city').val().toUpperCase()
  var zipcode = jQuery(container).find('input#company_zipcode').val()
  var country = jQuery(container).find('select#company_country option:selected').text().toUpperCase()

  var address = [street1, street2, [zipcode, city].filter(Boolean).join(' '), country].filter(Boolean)
  jQuery(container).find('textarea#company_address').val(address.join('\n'))
}

var init_company_form = () => {
  $('#kt_select2_company_country').select2({
    placeholder: "Select a country"
  });

  jQuery('.company-form-fields').on('blur keyup change', 'input#company_street1, textarea#company_street2, input#company_city, input#company_zipcode, select#company_country', (event) => {
    update_company_address(jQuery(event.target));
  });
}

jQuery(document).on('turbolinks:load', (event) => {
  init_company_form();
  init_company_search();
  jQuery('#referentable_contact').on('cocoon:after-insert', (event) => {
    init_contact_form();
  });
});

export { init_company_search, init_company_form };
