jQuery(document).on('turbolinks:load', (event) => {
  $("input[type='radio'][name='expense[expense_type]']").change(function () {
    if(this.value == 'mileage') {
      jQuery("#expense-default-fields").hide();
      jQuery("#expense-receipt-fields").hide();
      jQuery("#expense-mileage-fields").show();
    } else {
      jQuery("#expense-default-fields").show();
      jQuery("#expense-receipt-fields").show();
      jQuery("#expense-mileage-fields").hide();
    }
  });
});